export const search = async (question, filters = {}) => {
  try {
    const requestBody = {
      question,
      filters,
    };
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requestBody }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.jobId;
  } catch (error) {
    console.error("Error fetching UUID:", error);
  }
};

export const fetchResults = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/job/${id}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching results:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const sendEmail = async (email, id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/send-email/${id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching results:", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};
