import { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import "./FilterCard.css";
export const FilterCard = ({ toggleModal }) => {
  const [selectedSex, setSelectedSex] = useState("");
  const [fromYear, setFromYear] = useState("");
  const [toYear, setToYear] = useState("");
  const [age, setAge] = useState("");
  const [abstracts, setAbstracts] = useState("");

  const [error, setError] = useState({ from: "", to: "" });

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterSettings"));

    if (storedFilters) {
      setSelectedSex(storedFilters.selectedSex || "");
      setFromYear(storedFilters.fromYear || "");
      setToYear(storedFilters.toYear || "");
      setAge(storedFilters.age || "");
      setAbstracts(storedFilters.abstracts || "");
    } else {
      setSelectedSex("");
      setFromYear("");
      setToYear("");
      setAge("");
      setAbstracts("");
    }
  }, []);

  const handleClearAll = () => {
    setSelectedSex("");
    setFromYear("");
    setToYear("");
    setAge("");
    setAbstracts("");
    setError({ from: "", to: "" });
    localStorage.clear();
  };

  const validateYears = () => {
    let isValid = true;
    const errors = { from: "", to: "" };

    const yearRegex = /^\d{4}$/;

    if (fromYear && !yearRegex.test(fromYear)) {
      errors.from = "Enter a valid 4-digit year";
      isValid = false;
    }

    if (toYear && !yearRegex.test(toYear)) {
      errors.to = "Enter a valid 4-digit year";
      isValid = false;
    }

    if (fromYear && toYear && parseInt(fromYear) > parseInt(toYear)) {
      errors.from = "From year cannot be greater than To year";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const handleApply = () => {
    if (validateYears()) {
      const data = { selectedSex, fromYear, toYear, age, abstracts };

      localStorage.setItem("filterSettings", JSON.stringify(data));
      toggleModal();
    }
  };

  return (
    <div className="FilterCard">
      <button onClick={toggleModal} className="ToggleModalCloseButton">
        X
      </button>
      <div className="AgeAbstractContainer">
        <Box sx={{ minWidth: 120, flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="age-select-label">Age</InputLabel>
            <Select
              labelId="age-select-label"
              id="age-select"
              label="Age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            >
              <MenuItem value="18-24">18 - 24</MenuItem>
              <MenuItem value="25-34">25 - 34</MenuItem>
              <MenuItem value="35-44">35 - 44</MenuItem>
              <MenuItem value="45-54">45 - 54</MenuItem>
              <MenuItem value="55-60">55 - 60</MenuItem>
              <MenuItem value="60+">60+</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: { xs: 120, sm: 300 }, flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="abstract-select-label">
              Number of abstracts to screen
            </InputLabel>
            <Select
              labelId="abstract-select-label"
              id="abstract-select"
              label="Number of abstracts to screen"
              value={abstracts}
              onChange={(e) => setAbstracts(e.target.value)}
            >
              <MenuItem value="100">100</MenuItem>
              <MenuItem value="250">250</MenuItem>
              <MenuItem value="500">500</MenuItem>
              <MenuItem value="1000">1000</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>

      <div className="Gender">
        <FormControl>
          <FormLabel
            id="gender-radio-buttons-group-label"
            sx={{ fontWeight: "bold" }}
          >
            Gender
          </FormLabel>
          <RadioGroup
            aria-labelledby="gender-radio-buttons-group-label"
            value={selectedSex}
            onChange={(e) => setSelectedSex(e.target.value)}
            name="gender-radio-buttons-group"
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="YearRange">
        <h4>Reference Year Range</h4>
        <div className="Inputs">
          <TextField
            label="From"
            variant="outlined"
            value={fromYear}
            onChange={(e) => setFromYear(e.target.value)}
            error={!!error.from}
            helperText={error.from}
            FormHelperTextProps={{ style: { color: "red" } }}
          />
          <TextField
            label="To"
            variant="outlined"
            value={toYear}
            onChange={(e) => setToYear(e.target.value)}
            error={!!error.to}
            helperText={error.to}
            FormHelperTextProps={{ style: { color: "red" } }}
          />
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <button className="ClearButton" onClick={handleClearAll}>
          Clear All
        </button>
        <button className="ApplyButton" onClick={handleApply}>
          Apply
        </button>
      </Box>
    </div>
  );
};
