import React, { useRef, useState } from "react";
import "./Card.css";
import { FilterCard } from "../FilterCard/FilterCard";

export const Card = ({ search, defaultText }) => {
  const [text, setText] = useState(defaultText || "");
  const [isModelopen, setIsModelOpen] = useState(false);
  const filterCardRef = useRef();

  const closeFilterCard = (e) => {
    if (e.target === filterCardRef.current) {
      setIsModelOpen(false);
    }
  };

  const handleClick = () => {
    const trimmedText = text.trim();
    setText(trimmedText);
    search(trimmedText);
  };

  const toggleModal = () => {
    setIsModelOpen(!isModelopen);
  };
  return (
    <div className="Card">
      <div className="Header">
        <span className="GreenText">A</span>ccurate{" "}
        <span className="GreenText">I</span>nformation
      </div>
      <div className="SearchContainer">
        <div className="InputQuestionDiv">
          <textarea
            rows={5}
            cols={5}
            className="InputQuestion"
            placeholder="Ask your question..."
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="CardButtons">
        <div className="Button FilterButton" onClick={toggleModal}>
          Filter
        </div>
        <div className="Button SubmitButton" onClick={handleClick}>
          Submit your question
        </div>
      </div>
      {isModelopen && (
        <div
          className="FilterCardOverlay"
          ref={filterCardRef}
          onClick={closeFilterCard}
        >
          <FilterCard toggleModal={toggleModal} />
        </div>
      )}{" "}
    </div>
  );
};
