import React, { useState } from 'react';
import './Card.css';

export const Card = ({ search, defaultText }) => {
    const [text, setText] = useState(defaultText || '');

    const handleClick = () => {
        const trimmedText = text.trim();
        setText(trimmedText);
        search(trimmedText);
    }

    return (
        <div className='Card'>
            <div className='Header'>
                <span className='GreenText'>A</span>ccurate <span className='GreenText'>I</span>nformation
            </div>
            <div className='SearchContainer'>
                <div className='InputQuestionDiv' >
                    <textarea
                        rows={5}
                        cols={5}
                        className='InputQuestion'
                        placeholder="Ask your question..."
                        value={text}
                        onChange={(e) => {setText(e.target.value);
                        }} />
                </div>
            </div>
            <div className='Button' onClick={handleClick}>Submit your question</div>
        </div>
    )
}
