import React from 'react';
import { AboutCard } from '../../Components/AboutCard/AboutCard';

function About() {
  return (
    <AboutCard />
  );
}

export default About;

