import "./Home.css";
import { Card } from "../../Components/Card/Card";
import RecentCard from "../../Components/RecentCard/RecentCard";
import { init } from "@fullstory/browser";
import { useNavigate } from "react-router-dom";
import { search } from "../../Utils/functions";

init({ orgId: "o-200RRF-na1" });

function Home() {
  const navigate = useNavigate();

  const handleSearchAndNavigate = async (question) => {
    try {
      const id = await search(question); // Assuming search is an async function
      if (id) {
        navigate(`/search/${id}`); // Replace with your actual path
      }
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  return (
    <div className="Home">
      <Card search={handleSearchAndNavigate} />
      <RecentCard search={handleSearchAndNavigate} />
    </div>
  );
}

export default Home;
