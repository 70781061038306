import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FoundCard } from '../../Components/FoundCard/FoundCard';
import { ArticleCard } from '../../Components/ArticleCard/ArticleCard';
import CircularProgress from '@mui/material/CircularProgress';
import OrderCard from '../../Components/OrderCard/OrderCard'
import './Search.css'
import TrustCard from '../../Components/TrustCard/TrustCard';
import EmailCard from '../../Components/EmailCard/EmailCard';
import { Card } from '../../Components/Card/Card';
import { search, fetchResults } from '../../Utils/functions';
import { useNavigate } from 'react-router-dom';
// import EmailNotificationCard from '../../Components/EmailNotificationCard/EmailNotification';

function Search() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [AvgtrustScore, setAvgTrustScore] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [emailMessage, setEmailMessage] = useState("")
  // const [showEmailNotif, setShowEmailNotif] = useState(false);
  const [screenedArticles, setScreenedArticles] = useState([]);
  const [synthesisResult, setSynthesisResult] = useState(null);
  const [qualifiedAbstracts, setQualifiedAbstracts] = useState(0);
  const [totalAbstracts, setTotalAbstracts] = useState(0);
  const [question, setQuestion] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        setIsLoading(true)
        const data = await fetchResults(id);

        setStatus(data.status);

        // If the job is complete, stop polling and display the result
        if (data.status === 'Completed') {
          setShowModal(false)
          setScreenedArticles(data.extractedData);
          setSynthesisResult(data.synthesisResult);
          setQualifiedAbstracts(data.qualifiedAbstracts);
          setTotalAbstracts(data.totalAbstracts);
          setQuestion(data.question);
          setAvgTrustScore(data.trustScore);
          setEmail(data.email)
          setDate(data.updatedAt)
          clearInterval(intervalId);
          setIsLoading(false);
        }
        else if (data.status === 'Error') {
          setShowModal(false)
          clearInterval(intervalId);
        } else {
          if (!isModalClosed) {
            setShowModal(true)
          }
        }
      } catch (error) {
        console.error("Error fetching job status:", error);
        clearInterval(intervalId);
        setIsLoading(false);
      }
    }, 1000); // Poll every 3 seconds

    return () => clearInterval(intervalId);

  }, [id, isModalClosed]);

  const handleSearchAndNavigate = async (question) => {
    try {
      const id = await search(question); // Assuming search is an async function
      if (id) {
        navigate(`/search/${id}`, { replace: true }); // Replace with your actual path and ensure navigation is replaced to avoid duplicate history entries
      }
    } catch (error) {
      console.error('Error during search:', error);
    }
  };

  useEffect(() => {
    if (email) {
      setEmailMessage(`Report sent to ${email} on ${date}`)
    }
  }, [email, date])

  return (
    <>
      {isLoading ? (
        <div className='loading-container'>
          <CircularProgress />
          <p className='loading-status'>{status}</p>
        </div>
      ) : (
        <div className='SearchResults'>
          <Card defaultText={question} search={handleSearchAndNavigate} />
            <h3> {emailMessage} </h3>
          <OrderCard>
            <TrustCard trustScore={AvgtrustScore} trustText={synthesisResult} />
          </OrderCard>
          <div>
            <p>Please take a moment to provide your feedback: <a href="https://www.surveymonkey.com/r/MJGBFFY">MedRPT Feedback Survey</a></p>
          </div>
          <FoundCard qualifiedAbstracts={qualifiedAbstracts} totalAbstracts={totalAbstracts} />
          <OrderCard>
            {screenedArticles
              .map((article, index) => (
                <div key={index} id={`paper-${index + 1}`} className="article-container">
                  <ArticleCard
                    key={index}
                    title={article.title}
                    score={article.trustScore}
                    date={article.publicationDate}
                    authors={article.authors}
                    journal={article.journalTitle}
                    insight={article.insight}
                    results={article.extracted}
                    citations={article.citationCount}
                    pubmed_id={article.pubmed_id}
                    index={index}
                  />
                </div>
              ))}
          </OrderCard>
        </div>
      )}
      {showModal && (
        <EmailCard
          jobId={id}
          onClose={() => {
            setIsModalClosed(true)
            setShowModal(false)
          }}
        />
      )}
      {/*
      {showEmailNotif && (
        <EmailNotificationCard
        type="success"
        onClose={() => setShowEmailNotif(false)}
        />
      )}
      */}
    </>
  );
}

export default Search;
