import React, { useState, useEffect, useRef } from 'react';
import './EmailCard.css';
import { sendEmail } from '../../Utils/functions';

const EmailCard = ({ onClose, jobId }) => {
    const [email, setEmail] = useState('');
    const cardRef = useRef();

    const closeCard = (e) => {
        if (cardRef.current === e.target) {
            onClose();
        }
    }

    useEffect(() => {
        function handleEscapeKey(event) {
            if (event.code === 'Escape') {
                onClose();
            }
        }
        document.addEventListener('keydown', handleEscapeKey)
        return () => document.removeEventListener('keydown', handleEscapeKey)
    })

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            localStorage.setItem('email', email)
            console.log(localStorage.getItem('email'))
            await sendEmail(email, jobId);
            setEmail('');
            onClose();
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div className="Overlay" ref={cardRef} onClick={closeCard}>
            <div className="EmailCard">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="userEmail" className="EmailText">
                        You don't have to wait, we'll email you the result when it's ready.
                    </label>
                    <input
                        name="userEmail"
                        id="userEmail"
                        type="email"
                        className="EmailBox"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                    <div className="ButtonContainer">
                        <button type="submit" className="Button SubmitButton">
                            Send me the result
                        </button>
                        <button 
                            type="button" 
                            className="Button CancelButton"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EmailCard;
